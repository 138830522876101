@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.App {
    text-align: center;
    font-family: 'Quicksand', sans-serif !important;
    /* font-family: 'Poppins', sans-serif!important; */
    font-weight: 400;
    max-width: 100%;
    overflow-x: hidden;
    /* overflow-y: hidden */
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.lato_700 {
    font-family: 'Lato', sans-serif !important;
    font-weight: 700 !important;
}

.poppinss {
    font-family: 'Poppins', sans-serif !important;
}

.poppins-400 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
}

.poppins-700 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
}

.poppins-800 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 800 !important;
}

.poppins-600 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important;
}

.force-font-14 {
    font-size: 14px !important;
}

.force-font-12 {
    font-size: 12px !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

#get__started {
    position: fixed;
    bottom: 20px;
    text-align: center;
    right: 32%;
}

.get__started1 {
    position: fixed;
    width: 100%;
    bottom: 30px;
    text-align: center;
    right: 0%;
    color: #fff;
    font-family: 'Poppins', sans-serif !important;

    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
}

/* input[type="text"], */
/* input[type="number"], */
/* input[type="password"], */
input[type="email"] {
    font-size: 16px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1a1c1e !important;
}

.ant-tabs-ink-bar {
    background: #8b959f !important;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {

    margin-left: 28px !important;
}

.ant-tabs-tab {
    margin-right: 59px !important;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {

    justify-content: center !important;
}

.user-avatar {
    display: inline-block;
    float: inherit;
    width: 36px;
    height: 56px;
    margin: 5px;
    line-height: 36px;
    border-radius: 50%;
    background-size: cover;
    font-weight: 100;
    font-size: 18px;
    text-align: center;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
    color: white;
}

.user-avatar.-initial {
    background-color: black;
}

.user-avatar.-large {
    width: 100px;
    height: 100px;
    line-height: 64px;
    font-size: 32px;
}

.user-banner {
    display: inline-block;
    /* float: inherit; */
    width: 100%;
    height: 151px;
    /* margin: 0px; */
    line-height: 36px;
    /* border-radius: 50%; */
    background-size: cover;
    font-weight: 100;
    font-size: 18px;
    /* text-align: center; */
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
    color: white;
}

.user-banner-large {
    width: 100%;
    height: 140px;
    line-height: 64px;
    font-size: 32px;
}

table th,
td {
    font-size: 12px !important;
}

table th {
    width: 100vw;
}

#menuscreen p {
    font-weight: 500;
}

.splashomenu {
    background-image: url(../images/wood1.jpg);
    background-position: center;
    padding: 30px;
    /* font-family: 'Courgette', cursive; */
    font-family: 'Quicksand', sans-serif !important;
    background-repeat: repeat;

}

.splashomenualt {
    background-position: center;
    padding: 30px;
    /* font-family: 'Courgette', cursive; */
    font-family: 'Quicksand', sans-serif !important;
    background-color: #000;

}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }

    /* .splasho{
    animation: splash-anim 2s linear;

  } */
}

#private_pass {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #16151599;
    display: none;
    /* change if the mask should have another color then white */
    z-index: 99;
}

#play__bet {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    display: none;
    /* change if the mask should have another color then white */
    z-index: 999;
}

#play__blind {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    display: none;
    /* change if the mask should have another color then white */
    z-index: 9999;
}

#alert__back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #161515fa;
    display: none;
    /* change if the mask should have another color then white */
    z-index: 999;
    /* makes sure it stays on top */
}

#send_iv_back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #16151580;
    display: block;
    /* change if the mask should have another color then white */
    z-index: 9999999;
    /* makes sure it stays on top */
}

#moderator_invite_diplay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #16151580;
    display: block;
    z-index: 9999999;
}

#alert__back_err {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #161515fa;
    display: none;
    /* change if the mask should have another color then white */
    z-index: 99999;
    /* makes sure it stays on top */
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #161515fa;
    display: none;
    /* change if the mask should have another color then white */
    z-index: 99999;
    /* makes sure it stays on top */
}

#status {
    width: 100%;
    height: 200px;
    position: absolute;
    /* left: 17%; */
    /* centers the loading animation horizontally one the screen */
    top: 40%;
    /* centers the loading animation vertically one the screen */
    /* background-image: url(./images/icon-1.png); */
    /* path to your loading animation */
    background-repeat: no-repeat;
    background-position: center;
    /* margin: -100px 0 0 -100px; */
    background-size: 400px;
    /* is width and height divided by two */
}

.fixed__top {
    position: fixed;
    top: 0;
    z-index: 9;
}

.adm-list-item {

    margin-bottom: 20px !important;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.l-16 {
    line-height: 16px !important;
}

.comp-btn-outline {
    border: 2px solid #FFB43A;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    background: #FFF !important;
    color: #FFB43A !important
}

.q-opt {
    width: 100%;
    min-height: 69px;
    border: 2px solid #8D8D8D;
    box-sizing: border-box;
    border-radius: 9px;
}

.comp-btn {
    border: 2px solid #FFB43A;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    background: #FFB43A !important;
    color: #FFF !important
}

.personal-health {
    background: linear-gradient(90deg, #28936D 0%, #35EAAA 70.83%);
    border-radius: 9px 9px 0px 0px;
}

.fam-health {
    background: linear-gradient(90deg, #46A5BC 0%, #96E6FA 69.79%);
    border-radius: 9px 9px 0px 0px;
}

.report-col {
    background: #E5AF46;
    border-radius: 9px 9px 0px 0px;
}


.forthe {
    background: linear-gradient(90deg, #894D70 0%, #DD7CB5 65.63%);
    border-radius: 9px 9px 0px 0px;
}

.covid {
    background: linear-gradient(90deg, #8F3944 0%, #E9586A 68.75%);
    border-radius: 9px 9px 0px 0px;
}

.genetics {
    background: linear-gradient(90deg, #BB7B00 0%, #FFB63F 68.23%);
    border-radius: 9px 9px 0px 0px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes splash-anim {
    0% {
        background: #CB9748;
    }

    25% {
        background: #CB9748;
    }

    50% {
        background: #FFF;
    }

    100% {
        background: #0B0B0B;
    }

}

/* .ant-drawer-content {
  background-color: #202020!important;
}
.ant-drawer-header {
  background-color: #202020!important;
} */



.checkbox-dropdown {
    width: 100%;
    /* border: 1px solid #aaa; */
    padding: 10px;
    position: relative;
    /*     margin: 0 auto; */

    user-select: none;
}

/* Display CSS arrow to the right of the dropdown text */
.checkbox-dropdown:after {
    content: '';
    height: 0;
    position: absolute;
    width: 0;
    border: 6px solid transparent;
    border-top-color: #000;
    top: 50%;
    right: 10px;
    margin-top: -3px;
}

/* Reverse the CSS arrow when the dropdown is active */
.checkbox-dropdown.is-active:after {
    border-bottom-color: #000;
    border-top-color: #fff;
    margin-top: -9px;
}

.checkbox-dropdown-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    /* align the dropdown right below the dropdown text */
    border: inherit;
    border-top: none;
    left: -1px;
    /* align the dropdown to the left */
    right: -1px;
    /* align the dropdown to the right */
    opacity: 0;
    /* hide the dropdown */
    transition: opacity 0.4s ease-in-out;
    height: auto;
    /* overflow: scroll; */
    overflow-x: hidden;
    pointer-events: none;
    /* avoid mouse click events inside the dropdown */
}

.is-active .checkbox-dropdown-list {
    opacity: 1;
    /* display the dropdown */
    pointer-events: auto;
    /* make sure that the user still can select checkboxes */
    background: #d7d7d7;
    z-index: 99;
}

.checkbox-dropdown-list li label {
    display: block;
    border-bottom: 1px solid silver;
    padding: 10px;

    transition: all 0.2s ease-out;
}

.checkbox-dropdown-list li label:hover {
    background-color: #555;
    color: white;
}

.scrolling-wrapper .card {
    display: inline-block;
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.scrolling-wrapper-flexbox .card {
    flex: 0 0 auto;
    margin-right: 5px;
}

.card {
    /* border: 2px solid #cb9649; */
    width: 150px;
    /* height: 175px; */
    /* background: #cb9649; */
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
    /* height: 80px; */
    margin-bottom: 20px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper::-webkit-scrollbar,
.scrolling-wrapper-flexbox::-webkit-scrollbar {
    display: none;
}

.text__white {
    color: #FFFFFF !important;
}

.ant-picker {

    padding: 8px 11px 8px !important;
    border: 2px solid #d9d9d9 !important;
    border-radius: 10px !important;
}

.goog-te-banner-frame {
    display: none !important;
}

#goog-gt-tt {
    display: none !important;

}

.goog-te-gadget {

    padding: 20px !important;
}

.goog-te-combo,
.goog-te-banner *,
.goog-te-ftab *,
.goog-te-menu *,
.goog-te-menu2 *,
.goog-te-balloon * {
    font-family: 'Quicksand', sans-serif !important;
    font-size: 10pt;
    width: 100% !important;
    height: 39px !important;
    border-radius: 8px !important;
    padding: 10px !important
}

.ant-drawer-close {

    color: rgb(215 206 206) !important;

}

.text__black {
    color: black !important;
}

.new__content {

    margin-top: 20px;
}

.mobile__decide {
    float: left;
    width: 100%;
}

.m__nav__left {
    /* width: 20%; */
    background-color: #202020;
    height: 100%;
    position: fixed;
}

@media only screen and (max-width: 500px) {

    .new__content {
        margin-top: -10px;

        /* float:none; */
        /* width: calc(100% - 1.4rem); */
        /* width: 100%; */
    }

    .mobile__none {
        display: none !important;
    }

    .mobile__decide {
        float: none;
        width: calc(100% - 1.4rem);
    }
}

.new__bg {
    background-image: linear-gradient(310deg, #1d0b24, #524514) !important;
}

.bg__bge {
    background: #F6F7FB !important;
}

.navbar-dark .navbar-nav .nav-link {
    font-size: 12px;
}

.lloyd-logo {
    width: 20vmin;
    pointer-events: none;
}

.bg_mwb {
    background-color: #5A020E !important;
}

.full_width {
    width: 100% !important;
}

@media (prefers-reduced-motion: no-preference) {
    .lloyd-logo {
        animation: 1s infinite lloyd-logo-spin;
        animation-duration: .8s;
    }
}

@keyframes lloyd-logo-spin {
    50% {
        transform: scale(1.6, 1.6)
    }

    100% {
        transform: scale(1, 1)
    }

}

@media only screen and (min-width: 700px) {

    body {
        overflow-x: hidden;
    }
}

.capsule-default {
    background-color: none;
}

.capsule-fill {
    background-color: #BD8C93;
}

.container__custom {
    width: 100%;
    padding-right: 50px;
    padding-left: 50px;
    margin-right: auto;
    margin-left: auto;
}

.container__custom__lite {
    width: 100%;
    padding-right: 50px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}

.container__custom__34 {
    width: 100%;
    padding-right: 34px;
    padding-left: 34px;
    margin-right: auto;
    margin-left: auto;
}

.display__webkit {
    display: -webkit-inline-box !important;
}

.container-fluid-override {
    width: 100%;
    padding-right: 12px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

[type="radio"]:checked+label:after {
    background-color: red !important;
    border: 2px solid red !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 12px !important;
}

.css-1a1fmpi-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid #5A020E !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #5A020E !important;
    font-family: 'Poppins', sans-serif !important;

}

.container-central {
    width: 100%;
    padding-right: 25px !important;
    padding-left: 25px !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Poppins', sans-serif !important;
}

.row__custom {
    display: flex;
    flex-wrap: wrap;
    margin-right: -8px !important;
    margin-left: -5px !important;
}

.col-10-no-margin-left {
    padding-left: 0px !important;
}

.css-dmmspl-MuiFormGroup-root {
    display: flow-root !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-family: 'Poppins' !important;
    font-size: 13px !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #000 !important;
}

.css-1h7anqn {
    display: inline-block !important;
    flex-flow: column wrap !important;
}

.css-mqt2s5 {
    font-family: 'Poppins' !important;
    font-size: 13px !important;
}

.css-9l3uo3 {
    font-family: 'Poppins' !important;
    font-size: 13px !important;
}

.css-1a5icme.Mui-checked {
    color: rgb(23 23 23) !important;
}

.bg__menu {
    background: #F6F4FD !important;
}

/* .css-62e83j-MuiCircularProgress-root {
width: 15px!important;
height: 15px!important;
} */
@media only screen and (min-width: 380px) {



    .wallet__align {
        margin-left: 0px !important;
    }

}

@media only screen and (max-width: 360px) {


    .wallet__align {
        margin-left: -10px !important;
    }

}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #34485d !important;
}

.ant-notification-notice {
    z-index: 999999 !important;
}

.ant-rate {
    font-size: 11px !important;
}



.ant-rate-star:not(:last-child) {
    margin-right: 2px !important;
}

.anticon svg {
    font-size: 40px !important;
}

.ant-radio-button-wrapper {
    font-size: 12px !important;
    font-family: 'Raleway', sans-serif !important;
}